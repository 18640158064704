<script setup>
import { onMounted, defineProps } from "vue";
// import OvenPlayer from "../../modified-ovenplayer/dist/ovenplayer";

const props = defineProps({
    id: {
        type: String,
        default: "player-user"
    },
    sources: {
        type: Array,
        default: [{ type: "we", file: "https://vod.ome-test-2.bemyvega.dev/default_app_TZP2jwRJFmxjHDGgrGzb34-ptz/2022-11-18T10:58:25+0000/llhls.m3u8" }]
    }
});

onMounted(() => {
    const newWindowObject = window;
    const ovenplayer = newWindowObject.OvenPlayer;

    function hideCam() {
        if (document.getElementById("floating-cam")) {
            document.getElementById("floating-cam").style.display = "none";
        }
    }

    function showCam() {
        document.getElementById("floating-cam").style.display = "block";
        document.querySelector("#cam-wrapper .op-center-controls").style.display = "none";
        document.querySelector("#cam-wrapper .op-right-controls").style.display = "none";
        document.querySelector("#cam-wrapper .op-left-controls .op-play-controller").style.display = "none";
        document.querySelector("#cam-wrapper .op-left-controls .op-time-display").style.display = "none";
    }

    var ovenPlayer = null;
    function loadOvenPlayer() {
        hideCam();
        if (ovenPlayer) {
            ovenPlayer.remove();
        }

        ovenPlayer = ovenplayer.create(props.id, {
            autoStart: true,
            autoFallback: true,
            mute: false,
            sources: props.sources
        });

        ovenPlayer.on("stateChanged", (event) => {
            if (event.newstate == "playing") {
                showCam();
            }
        });

        ovenPlayer.on("error", function () {
            setTimeout(function () {
                hideCam();
                loadOvenPlayer();
            }, 1000);
        });
    }
    loadOvenPlayer();
});
</script>

<template>
    <div id="cam-wrapper">
        <div :id="id"></div>
    </div>
</template>

<style scoped>
#cam-wrapper .op-center-controls {
    display: none;
}

#cam-wrapper .op-right-controls {
    display: none;
}
</style>
