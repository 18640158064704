<template>
    <main>
        <Player :id="cams[0]" text="ptz" :sources="[{ type: 'webrtc', file: source1 }]" />
        <Player :id="cams[1]" text="fixed" :sources="[{ type: 'webrtc', file: source2 }]" />
        <Player :id="cams[2]" text="desktop" :sources="[{ type: 'webrtc', file: source3 }]" />

        <Cam :controls="false" :sources="[{ type: 'webrtc', file: 'wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-user' }]" />

        <div class="cam-buttons">
            <Button id="start-cam-button" label="Start Cam" :disabled="state == `streaming`" :class="state == `streaming` ? `inactive` : `active`" @click="startCam"></Button>
            <Button id="stop-cam-button" label="Stop Cam" :disabled="state != `streaming` || wsId != streamerId" :class="state == `streaming` && wsId == streamerId ? `active` : `inactive`" @click="stopCam"></Button>
        </div>

        <h4>State: {{ state }}</h4>
        <h4>ws ID: {{ wsId }}</h4>
        <h4>Streamer ID: {{ streamerId }}</h4>
    </main>
</template>

<script>
import Player from "../../components/Player.vue";
import Cam from "../../components/Cam.vue";
import OvenLiveKit from "ovenlivekit";
import { env_config } from "../../../config";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "TestStreaming",
    components: {
        Player,
        Cam
    },
    data() {
        return {
            cams: ["player-id1", "player-id2", "player-id3"],
            source1: "wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-ptz",
            source2: "wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-fixed",
            source3: "wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-desktop",
            op: null,
            connection: null,
            state: "",
            streamerId: "none",
            wsId: uuidv4()
        };
    },
    created() {
        // Hide sidebar
        document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");

        // Connect to Websocket Server
        console.log("Starting connection to WebSocket Server...");

        if (env_config.env_mode == "development") {
            // this.connection = new WebSocket(`ws://localhost:8000/api/v1/recordings/ws-stream?ws-id=${this.wsId}`);
            this.connection = new WebSocket(`ws://localhost:8001/ws/webcam?ws-id=${this.wsId}`);
        } else {
            // this.connection = new WebSocket(`wss://test.${env_config.WEBRTC_ROOT}/api/v1/recordings/ws-stream?ws-id=${this.wsId}`);
            this.connection = new WebSocket(`wss://test.${env_config.WEBRTC_ROOT}/ws/webcam?ws-id=${this.wsId}`);
        }

        this.connection.onopen = function () {
            console.log("Successfully connected to the WebSocket Server");
        };

        this.connection.onmessage = (event) => {
            console.log(event);
            const data = JSON.parse(event.data);
            console.log(data);
            this.state = data.state;
            this.streamerId = data.ws_id;
        };

        this.connection.onerror = function () {
            console.log("websocket error");
            if (this.connection) {
                this.connection.send(JSON.stringify({ state: "stopped" }));
            }
        };

        // Remove OvenLiveKit streaming if connection with WebSocket closes
        this.connection.onclose = () => {
            console.log(`websocket ${this.wsId} close`);
            this.connection.send(JSON.stringify({ state: "stopped" }));
            this.state = "stopped";
            if (this.op) {
                this.op.remove();
            }
        };
    },
    mounted() {
        const cams = ["player-id1", "player-id2", "player-id3"];
        let index = 0;

        function setPlayers() {
            document.getElementById(cams[index]).parentNode.style.display = "block";
            for (let i = 0; i < 3; i++) {
                const cam = document.getElementById(cams[i]);
                if (i != index) {
                    cam.parentNode.style.display = "none";
                }
            }
        }

        function setFullScreen() {
            const element = document.getElementById(cams[index]);
            const requestFullScreen = element.requestFullscreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
            requestFullScreen.call(element);
            document.documentElement.style.filter = "";
        }

        setPlayers();

        const previousButtons = document.querySelectorAll("button.op-button.op-previous-cam-button");
        const nextButtons = document.querySelectorAll("button.op-button.op-next-cam-button");

        document.body.addEventListener("click", function (event) {
            for (let i = 0; i < previousButtons.length; i++) {
                if (previousButtons[i].contains(event.target)) {
                    index = index - 1;
                    if (index < 0) {
                        index = 2;
                    }
                    setPlayers();
                    if (window.innerHeight == screen.height) {
                        document.documentElement.style.filter = "brightness(0%)";
                        document.exitFullscreen();
                        setTimeout(() => setFullScreen(), 500);
                    }
                    break;
                } else if (nextButtons[i].contains(event.target)) {
                    index = index + 1;
                    if (index > 2) {
                        index = 0;
                    }
                    setPlayers();
                    if (window.innerHeight == screen.height) {
                        document.documentElement.style.filter = "brightness(0%)";
                        document.exitFullscreen();
                        setTimeout(() => setFullScreen(), 500);
                    }
                    break;
                }
            }
        });
    },
    beforeUnmount() {
        document.getElementById("app").firstElementChild.classList.remove("layout-static-sidebar-inactive");
    },
    methods: {
        hideAllPlayers() {
            for (let i = 0; i < this.cams.length; i++) {
                document.getElementById(this.cams[i]).style.display = "none";
            }
        },
        setFilter() {
            for (let i = 0; i < this.cams.length; i++) {
                document.getElementById(this.cams[i]).style.filter = "invert(100%)";
            }
        },
        startCam() {
            /* OvenLiveKit config */
            var config = {
                callbacks: {
                    connected: () => {
                        console.log("CALLBACK CONNECTED");
                        this.connection.send(JSON.stringify({ state: "streaming" }));
                    },
                    connectionClosed: () => {
                        console.log("CALLBACK CONNECTION CLOSED");
                        this.connection.send(JSON.stringify({ state: "stopped" }));
                        this.connection.close();
                    },
                    error: (event) => {
                        if (event == "Cannot create offer") {
                            console.log("Sending state streaming");
                            // this.state = "streaming"
                            this.connection.send(JSON.stringify({ state: "streaming" }));
                        }
                    }
                }
            };

            /* OvenLiveKit initialization */
            let ovenLivekit = OvenLiveKit.create(config);
            this.op = ovenLivekit;
            ovenLivekit
                .getUserMedia({
                    audio: true,
                    video: true
                })
                .then(() => {
                    if (this.connection.readyState !== WebSocket.CLOSED || this.connection.readyState !== WebSocket.CLOSING) {
                        ovenLivekit.startStreaming("wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-user?direction=send");
                    } else {
                        this.stopCam();
                    }
                });
        },
        stopCam() {
            if (this.op) {
                this.op.remove();
                this.connection.send(JSON.stringify({ state: "stopped" }));
            }
        }
    }
};
</script>

<style>
.cam-buttons {
    display: flex;
    justify-content: center;
    margin: 2em;
    gap: 0.5em;
}

.active {
    background-color: green;
    color: white;
    cursor: pointer;
}

.inactive {
    background-color: lightgrey;
    color: darkgrey;
    cursor: none;
}
</style>
